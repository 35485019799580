<template>
	<div class="pc-container">
		<template v-for="(item,type) in list.types">
			<div class="box">
				<div class="box-title">{{item}}</div>
				<div class="list">
					<div v-for="(person,index) in list.persons" :key="index" v-if="person.type == type" class="list-item" @click="toDetail(person.id)">
						<img :class="['img', { 'fade-up': person.show_hover }]" 
						:src="person.show_hover ? person.pic2 : person.pic1" 
						@mouseenter="changeShowHover(person.id)" 
						@mouseleave="changeShowHover(person.id)" />
						<div class="info">
							<div class="position">{{person.tags}}</div>
							<div class="name">{{person.name}}</div>
							<div class="desc">{{person.intro}}</div>
						</div>
					</div>
				</div>
			</div>
		</template>
	</div>
</template>

<script>
    export default {
        data() {
            return {
				list: {
					types: [],
					persons: []
				}
            }
        },
		created() {
		    this.getList()
		},
        methods: {
			getList() {
				this.$api.team_person({},300000).then(res => {
				    this.list = res.data
					this.list.persons = this.list.persons.map(item => {
						item.show_hover = false
						return item
					})
				})
			},
			
			changeShowHover(id) {
				this.list.persons = this.list.persons.map(item => {
					if(item.id == id) {
						item.show_hover = !item.show_hover
					}
					return item
				})
			},
			
			toDetail(id) {
				this.$router.push({
					path: '/TeamDetail',
					query: {
						id
					}
				})
			}
        }
    }
</script>

<style lang="scss" scoped>
	.pc-container {
		display: flex;
		flex-direction: column;
		align-items: center;
		width: 100%;
		box-sizing: border-box;
		padding: 50px 0;
		background-color: #f6f6f6;
		
		.box {
			width: 1200px;
			
			&:not(:last-child) {
				margin-bottom: 65px;
			}
			
			.box-title {
				position: relative;
				width: 100%;
				margin-bottom: 40px;
				font-size: 30px;
				font-weight: bold;
				color: #000000;
				text-align: center;
				word-wrap: break-word;
				
				&::after {
					position: absolute;
					bottom: -10px;
					left: 50%;
					transform: translateX(-50%);
					content: '';
					width: 100px;
					height: 4px;
					border-radius: 4px;
					background-color: #5466EF;
				}
			}
			
			.list {
				display: flex;
				flex-wrap: wrap;
				width: 100%;
				
				.list-item {
					position: relative;
					width: 384px;
					height: 576px;
					margin-left: 24px;
					cursor: pointer;
					
					&:nth-child(3n+1) {
						margin-left: 0;
					}
					
					&:nth-child(n+4) {
						margin-top: 24px;
					}
					
					@keyframes fadeUp {
						0% {
							opacity: 0;
						}
					
						100% {
							opacity: 1;
						}
					}
					
					.img {
						display: block;
						width: 100%;
						height: 100%;
						border-radius: 6px;
					}
					
					.fade-up {
						animation: fadeUp .5s;
						animation-timing-function: linear;
						-webkit-animation: fadeUp .5s;
						-webkit-animation-timing-function: linear;
					}
					
					.info {
						position: absolute;
						bottom: 28px;
						left: 50%;
						transform: translateX(-50%);
						width: calc(100% - 48px);
						
						.position {
							width: 100%;
							margin-bottom: 10px;
							font-size: 14px;
							font-weight: bold;
							color: #FFFFFF;
							word-wrap: break-word;
						}
						
						.name {
							width: 100%;
							margin-bottom: 10px;
							font-size: 28px;
							font-weight: bold;
							color: #FFFFFF;
							word-wrap: break-word;
						}
						
						.desc {
							width: 100%;
							font-size: 16px;
							font-weight: bold;
							color: #FFFFFF;
							word-wrap: break-word;
						}
					}
				}
			}
		}
	}
</style>